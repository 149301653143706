import axiosInstance from "../AxiosInstance";
import {
  APPROVE_USER_DETAILS,
  DELETE_USERS,
  POST_USER_DETAILS,
} from "./UserApiEndPoints";

export function getAllUsers(currentPage, limit, search) {
  return axiosInstance.get(
    `admin/user/countUsers?page=${currentPage}&limit=${limit}&search=${search}`
  );
}

export function getUserBookingApi(id, page, limit, status) {
  return axiosInstance.get(
    `admin/user/countBookingUser?page=${page}&limit=${limit}&userId=${id}&search=${status}`
  );
}
export function getReferenceUser(userId) {
  return axiosInstance.get(
    `admin/user/countReferalUsers?page=0&limit=100&userId=${userId}`
  );
}
export function getUserBooingApi(wineryId, type, currentPage, limit) {
  let url = `admin/auth/getListing?type=${type}&page=${currentPage}&limit=${limit}`;

  if (wineryId) {
    url += `&wineryId=${wineryId}`;
  }
  return axiosInstance.get(url);
}

export function deleteUser(id) {
  return axiosInstance.delete(DELETE_USERS + `?id=${id}`);
}

export function blockUser(id) {
  const postData = { userId: id };
  return axiosInstance.put("admin/user/disable", postData);
}
export function setUserCommission(amount) {
  const postData = { number: amount };
  return axiosInstance.post("admin/home/referralAmout", postData);
}

export function approveUser(userId) {
  const postData = { userId };
  return axiosInstance.post(APPROVE_USER_DETAILS, postData);
}

export async function changePasswordApi(oldPassword, newPassword) {
  const data = { newPassword, oldPassword };
  const response = await axiosInstance.put(`admin/auth/changePassword`, data);
  return response;
}

export async function policiesServicesApi(text, type, userType) {
  const data = { type: type, text: text, userType: userType };
  const response = await axiosInstance.put(`admin/user/policy`, data);
  return response;
}
export async function getTermsServicesApi(type) {
  const response = await axiosInstance.get(
    `admin/auth/policies?userType=${type}`
  );
  return response;
}
export async function getCancellationPolicyApi(type) {
  const response = await axiosInstance.get(
    `admin/auth/cancellationPolicy?userType=${"user"}`
  );
  return response;
}

export function postUser(
  responseImage,
  fname,
  lname,
  email,
  dob,
  phNumber,
  typeOfTrainerSelected,
  locationSelected,
  servicesSelected,
  years,
  clients,
  noOfCertificate,
  fitnessCertificate,
  password,
  countryCode
) {
  const postData = {
    profile: responseImage,
    fName: fname,
    lName: lname,
    email,
    dob,
    phone: phNumber,
    typeOfTrainer: typeOfTrainerSelected,
    trainningLocation: locationSelected,
    services: servicesSelected,
    years,
    clients,
    noOfCertificate: noOfCertificate,
    fitnessCertificate: fitnessCertificate,
    password,
    countryCode,
  };

  return axiosInstance.post(POST_USER_DETAILS, postData);
}

export function payoutReferPaymentApi(postData) {
  return axiosInstance.post("admin/user/payoutRefPayment", postData);
}
//======================Vendor Services =================
export function getAllApproveVendors(currentPage, limit, search) {
  return axiosInstance.get(
    `admin/user/countVerifiedVendor?page=${currentPage}&limit=${limit}&search=${search}`
  );
}
export function getVendors(currentPage, limit, search) {
  return axiosInstance.get(
    `admin/user/countVendor?page=${currentPage}&limit=${limit}&search=${search}`
  );
}
export function ApproveVendorApi(postData) {
  return axiosInstance.put("admin/user/verifyVendor", postData);
}
export function VendorBlockApi(id) {
  const postData = { vendorId: id };
  return axiosInstance.put("admin/user/disableVendor", postData);
}
export async function setVendorCommissionApi(price, id) {
  const postData = { vendorId: id, amount: price };
  const response = await axiosInstance.post(
    "admin/home/vendorTransfer",
    postData
  );
  return response;
}

export function deleteVendorApi(id) {
  return axiosInstance.delete(`/admin/home/deleteVendors?vendorId=${id}`);
}
export function deleteUserApi(id) {
  return axiosInstance.delete("admin/user/deleteUsers" + `?userId=${id}`);
}
export function deleteReviewApi(data) {
  return axiosInstance.delete(
    "admin/user/removeReview" +
      `?reviewId=${data?.vendorId}&reason=${data?.message}`
  );
}

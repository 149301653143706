import React, { useEffect, useState } from "react";
import { Col, Card, Table, Spinner, Badge } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import {
  getReferenceUser,
  getUserBooingApi,
} from "../../services/User/UserService";
import toast from "react-hot-toast";
import Pagination from "../common/Pagination";
import moment from "moment";

export default function BookingWinery({ location }) {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const winery = location?.state;

  const limit = 10;

  function getTableData() {
    setLoader(true);
    getUserBooingApi(winery?.id && winery?.id, winery?.type, currentPage, limit)
      .then((response) => {
        setData(response?.data?.data);
        setLoader(false);
        const total = response.data.data.count;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.data);
        setLoader(false);
      });
  }

  console.log(data);
  useEffect(() => {
    getTableData();
  }, [currentPage]);

  return (
    <>
      <div>
        <h3 className="p-3">{winery?.pageName}</h3>
      </div>
      <Col>
        <Card>
          <Card.Body>
            {loader ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <Spinner animation="border" />
              </div>
            ) : (
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>User Name</strong>
                    </th>
                    <th>
                      <strong>winery name</strong>
                    </th>
                    <th>
                      <strong>category</strong>
                    </th>
                    <th className="text-center">
                      <strong>Guests</strong>
                    </th>
                    <th>
                      <strong>Prize</strong>
                    </th>
                    <th>
                      <strong>Booking Slot</strong>
                    </th>
                    <th>
                      <strong>Date</strong>
                    </th>
                    {winery?.type === "cancel" && (
                      <th>
                        <strong>Canceled Date & Time</strong>
                      </th>
                    )}
                    <th>
                      <strong>Status</strong>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data?.bookings?.map((item, refIndex) => (
                    <tr key={refIndex}>
                      <td>{item?.userId?.name}</td>
                      <td>{item?.wineryId?.name}</td>
                      <td>{item?.wineryId?.category}</td>
                      <td className="text-center">{item?.guests || "N/A"}</td>

                      <td className="text-center">€{item?.amount}</td>
                      <td>
                        {item?.startTime || "N/A"}-{item?.endTime}
                      </td>

                      <td>{item?.date || "N/A"}</td>
                      {winery?.type === "cancel" && (
                        <td>{moment(item?.updatedAt).format("lll")}</td>
                      )}
                      <td>
                        {item.status === "confirm" ? (
                          <Badge variant="success light">Completed</Badge>
                        ) : item.status === "cancel" ? (
                          <Badge variant="danger light">
                            {item?.cancelledBy}
                          </Badge>
                        ) : (
                          <Badge variant="info light">Active</Badge>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {data?.count === 0 && !loader && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}
            {data?.count !== 0 && !loader && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Total Bookings:
                  <span
                    style={{
                      color: "#9b4444",
                      border: "1px solid #9b4444",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {data?.count}
                  </span>
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Button,
  Spinner,
} from "react-bootstrap";
import Pagination from "../common/Pagination";
import {
  deleteVendorApi,
  VendorBlockApi,
  getAllApproveVendors,
  setVendorCommissionApi,
} from "../../services/User/UserService";
import defaultImage from "../../images/emptyImg.jpg";
import Switch from "react-switch";
import toast from "react-hot-toast";
import PageTitle from "../layouts/PageTitle";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import AddCommission from "../modal/AddCommission";
import RejectVendorMessageModal from "../modal/RejectVendorMessageModal";
import DeletePopup from "../modal/DeletePopup";
import pdfIcon from "../../icons/pdf.png";

export default function VendorManagement(props) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.content}
    </Tooltip>
  );
  const [loader, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [addCommissionModal, setAddCommissionModal] = useState(false);
  const [vendorData, setVendorData] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const [vendorRejectData, setVendorRejectData] = useState({
    id: "",
    status: "",
  });
  const [showRejectModal, setShowRejectModal] = useState(false);

  const limit = 10;

  function handleError(e) {
    e.target.src = defaultImage;
  }
  function getTableData() {
    setLoader(true);
    getAllApproveVendors(currentPage, limit, search)
      .then((response) => {
        setList(response.data.data);
        setLoader(false);
        const total = response.data.data.vendorCount;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }
  function deleteVender(id) {
    setId(id);
    setShowDeleteModal(true);
  }
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };
  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  async function handleVenderAction(id) {
    setLoader(true);
    try {
      const response = await VendorBlockApi(id);
      if (response?.data?.data?.isBlocked) {
        toast.success("Vendor Blocked Successfully");
      } else {
        toast.success("Vendor Active Successfully");
      }
      console.log(response);
      getTableData();
    } catch (error) {
      toast.error(error);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    getTableData();
  }, [currentPage, search]);

  return (
    <div>
      <div>
        <div className="p-3">
          <PageTitle activeMenu="Vendors List" motherMenu="Vendor Management" />
        </div>
        <div className="form-group pl-3" style={{ width: "35%" }}>
          <input
            type="text"
            name="table_search"
            className="form-control"
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>
      <Col>
        {loader ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    {/* <th>
                      <strong>image</strong>
                    </th> */}
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>Email</strong>
                    </th>
                    <th>
                      <strong>PHONE</strong>
                    </th>
                    <th className="text-center">
                      <strong>Commission</strong>
                    </th>
                    <th className="text-center">
                      <strong>Proof of ownership</strong>
                    </th>
                    <th>
                      <strong>status</strong>
                    </th>
                    <th>
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {list?.vendors?.map((item, i) => (
                    <tr
                      key={i}
                      className={item.isDeleted ? "disabled-row" : ""}
                    >
                      {/* <td
                        onClick={() =>
                          props.history.push({
                            pathname: "vendor-details",
                            state: item,
                          })
                        }
                      >
                        <img
                          src={`https://staging-api.bookwineries.com/${item?.photo}`}
                          height={50}
                          width={50}
                          style={{ borderRadius: "25px" }}
                          onError={handleError}
                        />
                      </td> */}
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "vendor-details",
                            state: item,
                          })
                        }
                      >
                        {item?.name}
                      </td>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "vendor-details",
                            state: item,
                          })
                        }
                      >
                        {item?.email}
                      </td>

                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "vendor-details",
                            state: item,
                          })
                        }
                      >
                        <span>+{item?.countryCode} </span>
                        {item?.contact}
                      </td>
                      <td className="text-center">
                        {item?.vendorCommision || 0}%
                      </td>
                      <td className="text-center hover-table">
                        <a
                          href={`https://staging-api.bookwineries.com/${item?.document}`}
                        >
                          <img src={pdfIcon} height={25} />
                        </a>
                      </td>
                      <td>
                        {item?.isDeleted ? (
                          <Badge variant="danger light">Deleted</Badge>
                        ) : (
                          <label className="switch-disable">
                            <Switch
                              onChange={() => handleVenderAction(item._id)}
                              checked={item.isBlocked}
                              onColor={"#FF0000"}
                              handleDiameter={30}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            />
                          </label>
                        )}
                      </td>

                      <td>
                        <div class="d-flex" style={{ gap: "7px" }}>
                          <OverlayTrigger
                            placement="top"
                            overlay={(props) =>
                              renderTooltip({ ...props, content: "Commission" })
                            }
                          >
                            <div
                              class="btn btn-info  btn-xs sharp list-btn"
                              onClick={() => {
                                setAddCommissionModal(true);
                                setVendorData(item);
                              }}
                            >
                              <i class="fa fa-money"></i>
                            </div>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={(props) =>
                              renderTooltip({ ...props, content: "Delete" })
                            }
                          >
                            <div
                              class="btn btn-danger  btn-xs sharp list-btn"
                              style={{
                                cursor: "pointer",
                                backgroundColor: "red",
                                color: "white",
                              }}
                              onClick={() => deleteVender(item?._id)}
                            >
                              <i class="fa fa-trash"></i>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {list?.vendorCount === 0 && !loader ? (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-between">
                <div className="dataTables_info">
                  Total Vendors
                  <span
                    style={{
                      color: "#9b4444",
                      border: "1px solid #9b4444",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {list?.vendorCount}
                  </span>
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </Card.Body>
          </Card>
        )}
      </Col>
      {addCommissionModal && (
        <AddCommission
          show={addCommissionModal}
          handleClose={() => setAddCommissionModal(false)}
          vendorData={vendorData}
          refresh={getTableData}
          title={"Set Commissions"}
          api={setVendorCommissionApi}
        />
      )}
      {/* {showRejectModal && (
        <RejectVendorMessageModal
          show={showRejectModal}
          onHide={() => setShowRejectModal(false)}
          data={vendorRejectData}
          refresh={getTableData}
        />
      )} */}
      {showDeleteModal && (
        <DeletePopup
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          api={deleteVendorApi}
          id={id}
          successMessage={"Deleted Vendor successfully"}
          refresh={getTableData}
        />
      )}
    </div>
  );
}

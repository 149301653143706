import React, { useEffect, useState, useCallback } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Button,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import defaultImage from "../../images/emptyImg.jpg";
import Pagination from "../common/Pagination";
import toast from "react-hot-toast";
import {
  approveUser,
  blockUser,
  deleteUser,
  deleteUserApi,
  getAllUsers,
  setUserCommission,
} from "../../services/User/UserService";
import Switch from "react-switch";
import DeletePopup from "../modal/DeletePopup";
import AddCommission from "../modal/AddCommission";

export default function UserManagement(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isCommissionModal, setIsCommissionModal] = useState(false);

  const limit = 10;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.content}
    </Tooltip>
  );

  function getTableData() {
    setLoader(true);
    getAllUsers(currentPage, limit, search)
      .then((response) => {
        console.log(response.data.data);
        setUsers(response?.data?.data);

        setLoader(false);
        const total = response.data.data.userCount;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  function onDelete(id) {
    setId(id);
    setShowDeleteModal(true);
  }

  function onAction(id) {
    setLoader(true);
    blockUser(id)
      .then((response) => {
        getTableData();
        setLoader(false);
        if (response?.data?.data?.isBlocked) {
          toast.success("User Inactivate successfully");
        } else {
          toast.success("User Active successfully");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setLoader(false);
      });
  }

  useEffect(() => {
    getTableData();
  }, [currentPage, search]);

  return (
    <div>
      <PageTitle activeMenu="Users List" motherMenu="User Management" />
      <div className="d-flex justify-content-between align-items-center">
        <div className="form-group ml-3" style={{ width: "40%" }}>
          <input
            type="text"
            name="table_search"
            className="form-control"
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="pr-3">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setIsCommissionModal(true);
              setId({ vendorCommision: users?.referralAmout?.referralAmout });
            }}
          >
            Set referral Amount
          </button>
        </div>
      </div>

      <Col>
        {loader ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>EMAIL</strong>
                    </th>
                    <th>
                      <strong>PHONE NUMBER</strong>
                    </th>
                    <th className="text-center">
                      <strong>Referred Users</strong>
                    </th>
                    <th className="text-center">
                      <strong>pending Amount</strong>
                    </th>
                    <th className="text-center">
                      <strong>STATUS</strong>
                    </th>
                    <th>
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.users?.map((item, i) => (
                    <tr
                      key={i}
                      className={item.isDeleted ? "disabled-row" : ""}
                    >
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "/user-details",
                            state: item,
                          })
                        }
                      >
                        {item?.name}
                      </td>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "/user-details",
                            state: item,
                          })
                        }
                      >
                        {item?.email}
                      </td>
                      <td
                        onClick={() =>
                          props.history.push({
                            pathname: "/user-details",
                            state: item,
                          })
                        }
                      >
                        <span>+{item?.countryCode} </span> {item?.contact || 0}
                      </td>
                      <td
                        className="text-center hover-table"
                        onClick={() =>
                          props.history.push({
                            pathname: "/reference-management",
                            state: {
                              userId: item?._id,
                            },
                          })
                        }
                      >
                        {item?.refUser?.length || 0}
                      </td>
                      <td
                        className="text-center hover-table"
                        onClick={() =>
                          props.history.push({
                            pathname: "/reference-management",
                            state: {
                              userId: item?._id,
                            },
                          })
                        }
                      >
                        ${item?.refTotalPayment}
                      </td>
                      <td>
                        {item?.isDeleted ? (
                          <Badge variant="danger light">Deleted</Badge>
                        ) : (
                          <label className="switch-disable">
                            <Switch
                              onChange={() => onAction(item._id)}
                              checked={item.isBlocked}
                              onColor={"#FF0000"}
                              handleDiameter={30}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            />
                          </label>
                        )}
                      </td>
                      <td className="text-center">
                        <OverlayTrigger
                          placement="top"
                          overlay={(props) =>
                            renderTooltip({ ...props, content: "Delete" })
                          }
                        >
                          <div
                            class="btn btn-danger btn-xs sharp list-btn"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "red",
                              color: "white",
                            }}
                            onClick={() => onDelete(item._id)}
                          >
                            <i class="fa fa-trash"></i>
                          </div>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {users?.userCount === 0 && !loader && (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              )}
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Total Users
                  <span
                    style={{
                      color: "#9b4444",
                      border: "1px solid #9b4444",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {users?.userCount}
                  </span>
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </Card.Body>
          </Card>
        )}
      </Col>
      {showDeleteModal && (
        <DeletePopup
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          api={deleteUserApi}
          id={id}
          successMessage={"Deleted user successfully"}
          refresh={getTableData}
        />
      )}
      {isCommissionModal && (
        <AddCommission
          show={isCommissionModal}
          handleClose={() => setIsCommissionModal(false)}
          vendorData={id}
          refresh={getTableData}
          api={setUserCommission}
          title={"Set Amount"}
        />
      )}
    </div>
  );
}
